@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "IndoPak";
  src: url("../public/fonts/Naskh-Nastaleeq-IndoPak-QWBW.woff") format("woff");
}
@font-face {
  font-family: "nerd";
  src: url("../public/fonts/nerd.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/poppins.woff2") format("woff2");
}
@font-face {
  font-family: "Lilita";
  src: url("../public/fonts/lilita.woff2") format("woff2");
}
@font-face {
  font-family: "Merri";
  src: url("../public/fonts/merriweather.woff2") format("woff2");
}
@font-face {
  font-family: "AD";
  src: url("../public/fonts/ArchitectsDaughter-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "uthmani";
  src: url("../public/fonts/uthmani.woff2") format("woff2");
}
.selection {
  -webkit-appearance: none;
}
.save {
  direction: rtl;
}
/* 
body {
  margin: 0;
  background-color: #3f3f3f;
} */

.tisch {
  background-color: burlywood;
  background-image: url("../public/images/image.svg");
  animation-duration: 4s;
  animation-name: "transition";
}
.ghub {
  display: inline;
  vertical-align: auto;
  margin: 2px;
  margin-bottom: 3px;
}

.book {
  height: 100%;
}
.indopak {
  font-family: "IndoPak", sans-serif;
  direction: rtl;
  
}
.uthmani {
  font-family: "uthmani", sans-serif;
  direction: rtl;
}
.nerdy {
  font-family: "nerd";
}
.tran {
  animation-name: transition;
  animation-duration: 1.5s;
  
}
@keyframes transition {
  from {opacity: 0;}
  to {opacity: 1;}  
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.cover {
  height: 913px;
}
.swal-button {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #8d6e30;
  font-size: 12px;
  border: 1px solid #5b3e0d;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}

/* .swal-button:hover {
  background-color: #e8cc9c;
  color: #0000;
} */
.wideletters {
  letter-spacing: 0.009rem;
}
.headAyahTop {
  border-top: 3px solid black;
  margin-bottom: 15px;
}
.headAyahBottom {
  border-top: 3px solid black;
  margin-top: 15px;
}

.footerHR {
  border-top: 3px solid #94A8B8;
  margin-top: 15px;
}

.turnm {
  margin-right: 50rem;
}
.backm {
  margin-left: 50rem;
}
.number {
  font-family: "IndoPak", sans-serif;
}
.surah {
  font-family: "Poppins";
  font-size: xx-large;
}
.lilita {
  font-family: "Lilita";
}

.contents {
  direction: center;
  margin : 0 auto;
  display: flex;
  justify-content: center;
}
.content {
  direction: center;
  margin : 0 auto;
}
.mobile {
  margin-top: 50vh;
}
.halfHeight {
  height: 75vh;
}
.ltrs {
  text-align: ltr;
}
.ntext {
  font-family: "Poppins", sans-serif;
}
.title {
  font-family: "AD", sans-serif;
}
@media not all and (min-width: 1280px) {
  .text-xss {
      font-size: 10px/* 12px */;
      line-height: 1rem/* 16px */;
  }
}
.htmlstyle {
  background-image: url("../public/images/image.svg");
}
